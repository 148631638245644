// extracted by mini-css-extract-plugin
export var searchExpand = "button-module--search-expand--FnIGw";
export var expand = "button-module--expand--BLJLq";
export var searchCollapse = "button-module--search-collapse--4TT3a";
export var leftImage = "button-module--leftImage--+RFA+";
export var rightImage = "button-module--rightImage--+YBdk";
export var button = "button-module--button--T1jbp";
export var primaryBordered = "button-module--primaryBordered--tLbo-";
export var infoBordered = "button-module--infoBordered--S0j7d";
export var successBordered = "button-module--successBordered--6j0YR";
export var dangerBordered = "button-module--dangerBordered--LVJ6j";
export var primaryFilled = "button-module--primaryFilled--YwyCG";
export var infoFilled = "button-module--infoFilled--YOmdZ";
export var successFilled = "button-module--successFilled--qmdlx";
export var dangerFilled = "button-module--dangerFilled--54ZXy";
export var primaryTransparent = "button-module--primaryTransparent--r1ljv";
export var infoTransparent = "button-module--infoTransparent--q3ilV";
export var successTransparent = "button-module--successTransparent--tz6qh";
export var dangerTransparent = "button-module--dangerTransparent--syahT";
export var isLoading = "button-module--isLoading--idVpm";