// extracted by mini-css-extract-plugin
export var searchExpand = "card-module--search-expand--momB9";
export var expand = "card-module--expand--+lBn0";
export var searchCollapse = "card-module--search-collapse--gb9tH";
export var card = "card-module--card--qMjs+";
export var overlay = "card-module--overlay--TlNh8";
export var overlayDark = "card-module--overlayDark--ZCyjD";
export var content = "card-module--content--ycS4i";
export var more = "card-module--more--MvUE1";
export var square = "card-module--square--mdt7H";
export var squareLg = "card-module--square-lg--aM-Zx";
export var rectangle = "card-module--rectangle--RIHpu";
export var colSpan = "card-module--colSpan--LBKnl";